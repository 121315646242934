<template>
  <div v-if="voucher && !isLoading && srcImg" style="background-color: #fff;width: 100%;margin: 12px 0;border-radius: 18px;box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);padding: 0px 12px 30px 12px;">
    <p style="font-size:24px;font-weight: 700;text-align: left;margin-bottom: 0;">Скидки</p>
  <div style="display: flex;flex-direction: column;">
    <!--            <div style="display:none;" class="success-panel" :class="{'deactive':userAcuvuePoint<300}" :style="{'border': `1px ${(userAcuvuePoint>=300) ? 'solid' : 'dashed'} #D0D0D0`}">-->
    <!--              <svg v-if="userAcuvuePoint>=300" width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--                <g filter="url(#filter0_d_128_220)">-->
    <!--                  <rect x="4.5" y="3" width="48" height="48" rx="24" fill="#05A3E1"/>-->
    <!--                  <path d="M22.332 35.4L32.436 18.6H34.668L24.564 35.4H22.332ZM19.476 19.752C20.196 18.856 21.196 18.408 22.476 18.408C23.756 18.408 24.756 18.856 25.476 19.752C26.196 20.632 26.556 21.784 26.556 23.208C26.556 24.632 26.196 25.792 25.476 26.688C24.756 27.568 23.756 28.008 22.476 28.008C21.196 28.008 20.196 27.568 19.476 26.688C18.756 25.792 18.396 24.632 18.396 23.208C18.396 21.784 18.756 20.632 19.476 19.752ZM24.636 23.208C24.636 21.064 23.916 19.992 22.476 19.992C21.036 19.992 20.316 21.064 20.316 23.208C20.316 25.352 21.036 26.424 22.476 26.424C23.916 26.424 24.636 25.352 24.636 23.208ZM31.524 27.336C32.244 26.44 33.244 25.992 34.524 25.992C35.804 25.992 36.804 26.44 37.524 27.336C38.244 28.216 38.604 29.368 38.604 30.792C38.604 32.216 38.244 33.376 37.524 34.272C36.804 35.152 35.804 35.592 34.524 35.592C33.244 35.592 32.244 35.152 31.524 34.272C30.804 33.376 30.444 32.216 30.444 30.792C30.444 29.368 30.804 28.216 31.524 27.336ZM36.684 30.792C36.684 28.648 35.964 27.576 34.524 27.576C33.084 27.576 32.364 28.648 32.364 30.792C32.364 32.936 33.084 34.008 34.524 34.008C35.964 34.008 36.684 32.936 36.684 30.792Z" fill="white"/>-->
    <!--                </g>-->
    <!--                <defs>-->
    <!--                  <filter id="filter0_d_128_220" x="0.5" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">-->
    <!--                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>-->
    <!--                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>-->
    <!--                    <feOffset dy="1"/>-->
    <!--                    <feGaussianBlur stdDeviation="2"/>-->
    <!--                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>-->
    <!--                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_128_220"/>-->
    <!--                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_128_220" result="shape"/>-->
    <!--                  </filter>-->
    <!--                </defs>-->
    <!--              </svg>-->
    <!--              <svg v-else width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--                <g filter="url(#filter0_d_128_227)">-->
    <!--                  <path d="M22.332 35.4L32.436 18.6H34.668L24.564 35.4H22.332ZM19.476 19.752C20.196 18.856 21.196 18.408 22.476 18.408C23.756 18.408 24.756 18.856 25.476 19.752C26.196 20.632 26.556 21.784 26.556 23.208C26.556 24.632 26.196 25.792 25.476 26.688C24.756 27.568 23.756 28.008 22.476 28.008C21.196 28.008 20.196 27.568 19.476 26.688C18.756 25.792 18.396 24.632 18.396 23.208C18.396 21.784 18.756 20.632 19.476 19.752ZM24.636 23.208C24.636 21.064 23.916 19.992 22.476 19.992C21.036 19.992 20.316 21.064 20.316 23.208C20.316 25.352 21.036 26.424 22.476 26.424C23.916 26.424 24.636 25.352 24.636 23.208ZM31.524 27.336C32.244 26.44 33.244 25.992 34.524 25.992C35.804 25.992 36.804 26.44 37.524 27.336C38.244 28.216 38.604 29.368 38.604 30.792C38.604 32.216 38.244 33.376 37.524 34.272C36.804 35.152 35.804 35.592 34.524 35.592C33.244 35.592 32.244 35.152 31.524 34.272C30.804 33.376 30.444 32.216 30.444 30.792C30.444 29.368 30.804 28.216 31.524 27.336ZM36.684 30.792C36.684 28.648 35.964 27.576 34.524 27.576C33.084 27.576 32.364 28.648 32.364 30.792C32.364 32.936 33.084 34.008 34.524 34.008C35.964 34.008 36.684 32.936 36.684 30.792Z" fill="black"/>-->
    <!--                  <rect x="5" y="3.5" width="47" height="47" rx="23.5" stroke="#D0D0D0" stroke-dasharray="3 3"/>-->
    <!--                </g>-->
    <!--                <defs>-->
    <!--                  <filter id="filter0_d_128_227" x="0.5" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">-->
    <!--                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>-->
    <!--                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>-->
    <!--                    <feOffset dy="1"/>-->
    <!--                    <feGaussianBlur stdDeviation="2"/>-->
    <!--                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>-->
    <!--                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_128_227"/>-->
    <!--                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_128_227" result="shape"/>-->
    <!--                  </filter>-->
    <!--                </defs>-->
    <!--              </svg>-->

    <!--              <div style="font-size:12px;font-weight: normal;">Скидка 300 ₽ на одну покупку</div>-->
    <!--              <a v-if="userAcuvuePoint>=300" href="/howspendpoints"><mt-button type="primary">Как получить</mt-button></a>-->
    <!--              <a v-else><mt-button type="default" disabled>Ещё {{ 300-userAcuvuePoint }} баллов</mt-button></a>-->
    <!--            </div>-->
    <!--            <div style="display:none;" class="success-panel" :class="{'deactive':userAcuvuePoint<500}" :style="{'border': `1px ${(userAcuvuePoint>=500) ? 'solid' : 'dashed'} #D0D0D0`}">-->
    <!--              <svg v-if="userAcuvuePoint>=500" width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--                <g filter="url(#filter0_d_128_220)">-->
    <!--                  <rect x="4.5" y="3" width="48" height="48" rx="24" fill="#05A3E1"/>-->
    <!--                  <path d="M22.332 35.4L32.436 18.6H34.668L24.564 35.4H22.332ZM19.476 19.752C20.196 18.856 21.196 18.408 22.476 18.408C23.756 18.408 24.756 18.856 25.476 19.752C26.196 20.632 26.556 21.784 26.556 23.208C26.556 24.632 26.196 25.792 25.476 26.688C24.756 27.568 23.756 28.008 22.476 28.008C21.196 28.008 20.196 27.568 19.476 26.688C18.756 25.792 18.396 24.632 18.396 23.208C18.396 21.784 18.756 20.632 19.476 19.752ZM24.636 23.208C24.636 21.064 23.916 19.992 22.476 19.992C21.036 19.992 20.316 21.064 20.316 23.208C20.316 25.352 21.036 26.424 22.476 26.424C23.916 26.424 24.636 25.352 24.636 23.208ZM31.524 27.336C32.244 26.44 33.244 25.992 34.524 25.992C35.804 25.992 36.804 26.44 37.524 27.336C38.244 28.216 38.604 29.368 38.604 30.792C38.604 32.216 38.244 33.376 37.524 34.272C36.804 35.152 35.804 35.592 34.524 35.592C33.244 35.592 32.244 35.152 31.524 34.272C30.804 33.376 30.444 32.216 30.444 30.792C30.444 29.368 30.804 28.216 31.524 27.336ZM36.684 30.792C36.684 28.648 35.964 27.576 34.524 27.576C33.084 27.576 32.364 28.648 32.364 30.792C32.364 32.936 33.084 34.008 34.524 34.008C35.964 34.008 36.684 32.936 36.684 30.792Z" fill="white"/>-->
    <!--                </g>-->
    <!--                <defs>-->
    <!--                  <filter id="filter0_d_128_220" x="0.5" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">-->
    <!--                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>-->
    <!--                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>-->
    <!--                    <feOffset dy="1"/>-->
    <!--                    <feGaussianBlur stdDeviation="2"/>-->
    <!--                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>-->
    <!--                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_128_220"/>-->
    <!--                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_128_220" result="shape"/>-->
    <!--                  </filter>-->
    <!--                </defs>-->
    <!--              </svg>-->
    <!--              <svg v-else width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--                <g filter="url(#filter0_d_128_227)">-->
    <!--                  <path d="M22.332 35.4L32.436 18.6H34.668L24.564 35.4H22.332ZM19.476 19.752C20.196 18.856 21.196 18.408 22.476 18.408C23.756 18.408 24.756 18.856 25.476 19.752C26.196 20.632 26.556 21.784 26.556 23.208C26.556 24.632 26.196 25.792 25.476 26.688C24.756 27.568 23.756 28.008 22.476 28.008C21.196 28.008 20.196 27.568 19.476 26.688C18.756 25.792 18.396 24.632 18.396 23.208C18.396 21.784 18.756 20.632 19.476 19.752ZM24.636 23.208C24.636 21.064 23.916 19.992 22.476 19.992C21.036 19.992 20.316 21.064 20.316 23.208C20.316 25.352 21.036 26.424 22.476 26.424C23.916 26.424 24.636 25.352 24.636 23.208ZM31.524 27.336C32.244 26.44 33.244 25.992 34.524 25.992C35.804 25.992 36.804 26.44 37.524 27.336C38.244 28.216 38.604 29.368 38.604 30.792C38.604 32.216 38.244 33.376 37.524 34.272C36.804 35.152 35.804 35.592 34.524 35.592C33.244 35.592 32.244 35.152 31.524 34.272C30.804 33.376 30.444 32.216 30.444 30.792C30.444 29.368 30.804 28.216 31.524 27.336ZM36.684 30.792C36.684 28.648 35.964 27.576 34.524 27.576C33.084 27.576 32.364 28.648 32.364 30.792C32.364 32.936 33.084 34.008 34.524 34.008C35.964 34.008 36.684 32.936 36.684 30.792Z" fill="black"/>-->
    <!--                  <rect x="5" y="3.5" width="47" height="47" rx="23.5" stroke="#D0D0D0" stroke-dasharray="3 3"/>-->
    <!--                </g>-->
    <!--                <defs>-->
    <!--                  <filter id="filter0_d_128_227" x="0.5" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">-->
    <!--                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>-->
    <!--                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>-->
    <!--                    <feOffset dy="1"/>-->
    <!--                    <feGaussianBlur stdDeviation="2"/>-->
    <!--                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>-->
    <!--                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_128_227"/>-->
    <!--                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_128_227" result="shape"/>-->
    <!--                  </filter>-->
    <!--                </defs>-->
    <!--              </svg>-->

    <!--              <div style="font-size:12px;font-weight: normal;">Скидка 500 ₽ <br>на одну покупку</div>-->
    <!--              <a v-if="userAcuvuePoint>=500" href="/howspendpoints"><mt-button type="primary">Как получить</mt-button></a>-->
    <!--              <a v-else><mt-button type="default" disabled>Ещё {{ 500-userAcuvuePoint }} баллов</mt-button></a>-->
    <!--            </div>-->
    <p style="font-size:16px;font-weight: 400;text-align: left">Вам доступен купон MyACUVUE® на <b>1000₽</b><br> при покупке 2х упаковок с доставкой</p>
    <img :src="require(`../../assets/vouchers/${srcImg}`)" alt="" style="width: 100%;border-radius: 18px">
    <div style="width: 100%;display: flex;justify-content: right;flex-direction: column;align-items: flex-end;margin-top: 14px">
      <div  class="calc-line">
        <div>Обычная цена:</div>
        <div>{{curLense.price}}₽ + {{curLense.price}}₽ = <b>{{curLense.price*2}}₽</b></div>
      </div>
      <div class="calc-line">
        <div>Купон:</div>
        <div style="font-weight: 600">-1000₽ </div>
      </div>
      <div class="calc-line">
        <div>Доставка:</div>
        <div style="font-weight: 600">300₽</div>
      </div>
      <div class="calc-line">
        <div>За одну упаковку:</div>
        <div><s>{{curLense.price}}₽</s> <b>{{(((curLense.price*2+300)-1000)/2)}}₽</b></div>
      </div>
      <div class="calc-line">
        <div style="font-size: 24px">Итого:</div>
        <div style="font-weight: 600;font-size: 24px">{{((curLense.price*2+300)-1000)}}₽</div>
      </div>
    </div>
    <a :href="curLense.linkForReview" @click.prevent="sendData">
      <mt-button type="primary" :disabled="sendedText || sendError">
        <span v-if="sendedText">Ваша заявка отправлена</span>
        <span v-else>Заказать со скидкой 1000₽</span>
      </mt-button>
    </a>
    <div v-if="sendError" class="error-block">
      <p>В настоящее время нет связи с сервером MyACUVUE®.</p>
      <p>Свяжитесь с поддержкой через Telegram: <a href="https://t.me/myacuvue">@myacuvue</a></p>
    </div>
    <p style="font-size:12px;color: #757575;">Предложение действует до 31.12.2022</p>
  </div>

  </div>
  <div v-else-if="isLoading" style="background-color: #fff;width: 100%;margin: 12px 0;border-radius: 18px;box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);padding: 0px 12px 0px 12px;">
    <div style="display: flex;justify-content: center;align-items: center;margin: 20px 0">
      <mt-spinner type="snake"></mt-spinner>
    </div>
  </div>
</template>
<style>

</style>
<script>

import {Indicator} from "mint-ui";

export default {
  name: "voucher1000-page",
  props:['curLense', 'phone', 'curKey'],
  data: ()=>{
    return {
      isLoading: false,
      voucher: null,
      srcImg: null,
      sendedText: false,
      sendError: false
    }
  },
  async mounted() {
    if (this.phone) await this.getVoucher()
    // this.curLense.name = 'Acuvue Oasys 1-Day' // for test
    switch (this.curLense.name) {
      case 'Acuvue Oasys for astigmatism':
        this.srcImg = 'Gift-Oasys6Astigmatism.webp'
        break
      case  'Acuvue Oasys 6':
        this.srcImg = 'Gift-Oasys6.webp'
        break
      case 'Acuvue Oasys 12':
        this.srcImg = 'Gift-Oasys12.webp'
        break
      case 'Acuvue Oasys with Transitions':
        this.srcImg = 'Gift-OasysTransitions.webp'
        break
      case 'Acuvue Oasys 1-Day':
        this.srcImg = 'Gift-Oasys30.webp'
        break
      case 'Acuvue Oasys 1-Day for Astigmatism':
        this.srcImg = 'Gift-Oasys30Astigmatism.webp'
        break

      default:
        this.srcImg = null
        break
    }
  },
  methods: {
    async getVoucher() {
      this.isLoading=true
      await fetch(`${process.env.VUE_APP_API_SERVER}/find-voucher`, {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          'Content-Type':'application/json',
          "credentials": "include",
          "mode": 'no-cors',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          art_wb: this.curLense.art_wb,
          phone: this.phone.replace(/[^0-9]/g,"").slice(1),
          key: this.curKey,
          wb_order_id: this.curLense.wb_order_id,
          cur_point: this.curLense.acuvuePoint
        })
      })
          .then(resp=>resp.json())
          .then(resp=>{
            if (resp.code==0) this.voucher = resp.voucher

            this.isLoading=false
          })
          .catch(err=>{
            console.log('err', err)
            this.isLoading=false
          })
    },
    async sendData() {
      Indicator.open({text: 'Пожалуйста подождите, отправляем запрос менеджеру. Не закрывайте этой окно', spinnerType: 'snake'})
      // console.log({
      //   art_wb: this.curLense.art_wb,
      //   phone: this.phone.replace(/[^0-9]/g,"").slice(1),
      //   key: this.curKey,
      //   wb_order_id: this.curLense.wb_order_id
      // })
      await fetch(`${process.env.VUE_APP_API_SERVER}/send-voucher`, {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          'Content-Type':'application/json',
          "credentials": "include",
          "mode": 'no-cors',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          art_wb: this.curLense.art_wb,
          phone: this.phone.replace(/[^0-9]/g,"").slice(1),
          key: this.curKey,
          wb_order_id: this.curLense.wb_order_id,
          cur_point: this.curLense.acuvuePoint,
          result: this.voucher.name,
          seller: this.curLense.seller
        })
      })
          .then(resp=>resp.json())
          .then(resp=>{
            if (!resp.code) this.sendedText = true
            if (resp.code<0) this.sendError = true

            Indicator.close()
          })
          .catch(err=>{
            console.log('err', err)
            Indicator.close()
          })

    },
  },
  computed: {

  },
  watch: {


  },
  components: {

  },
};
</script>
