<template>
  <div id="app">
    <div class="container">
      <main-page :cur-key="route" />

      <div class="mint-tabbar is-fixed">
        <a class="mint-tab-item" style="color: #2c3e50" href="/howspendpoints">
          <div class="mint-tab-item-icon"><img src="./assets/acuvue_icon.png"></div>
          <div class="mint-tab-item-label">Как потратить баллы Acuvue</div>
        </a>
        <a class="mint-tab-item" style="color: #2c3e50" href="/contacts">
          <div class="mint-tab-item-icon"><img src="./assets/info_icon.png"></div>
          <div class="mint-tab-item-label">Контакты</div>
        </a>
        <a class="mint-tab-item" style="color: #2c3e50" href="https://www.acuvue.ru/sites/acuvue_ru/files/62yur-2022_.pdf" target="_blank">
          <div class="mint-tab-item-icon"><img src="./assets/pdf_icon.png"></div>
          <div class="mint-tab-item-label"> Правила программы <svg width="15" height="15" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.73333 5.28891V7.02225C6.73333 7.17548 6.67246 7.32244 6.56411 7.4308C6.45575 7.53915 6.30879 7.60002 6.15556 7.60002H2.97778C2.82454 7.60002 2.67758 7.53915 2.56923 7.4308C2.46087 7.32244 2.4 7.17548 2.4 7.02225V3.84447C2.4 3.69123 2.46087 3.54427 2.56923 3.43592C2.67758 3.32756 2.82454 3.26669 2.97778 3.26669H4.71111M5.86667 2.40002H7.6M7.6 2.40002V4.13336M7.6 2.40002L4.42222 5.5778" stroke="black" stroke-width="0.75"/>
          </svg></div>
        </a>
      </div>
    </div>

  </div>
</template>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #fff url("./assets/background.png") no-repeat 20px -180px;
  background-size:cover;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
}
.container {
  max-width: 700px;
}
@font-face {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 400;
  src: local('Pragmatica'), local('Pragmatica-Regular'),
  url('./assets/fonts/pragmatica.woff') format('woff'),
  url('./assets/fonts/pragmatica.ttf') format('truetype');
}
body {
  font-family: 'Pragmatica';
  margin: 0;
}
h1 {
  margin: 0;
  padding: 0;
}
</style>
<script>
// import MainPage from "@/views/Acuvue";

import MainPage from "@/views/Acuvue";
export default {
  name:'app-mainpage',
  components: {MainPage},
  data: () => {
    return {
      selected: false,
      route: '',
      isLoading: true,
    }
  },
  async beforeMount() {
    this.route = this.$route.path.substring(1)
    // console.log('app route: ')
    // console.log(this.route)
  },
  mounted() {

  }
}
</script>
