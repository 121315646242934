import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'mint-ui/lib/style.css'
import MintUI from 'mint-ui'
import { Swipe, SwipeItem, Navbar, TabItem, Cell, Field, Button, Tabbar, Picker, Radio, Popup, Checklist, } from 'mint-ui';

Vue.component(Picker.name, Picker);
const VueInputMask = require('vue-inputmask').default

Vue.component(Checklist.name, Checklist);
Vue.component(Popup.name, Popup);
Vue.component(Radio.name, Radio);
Vue.component(Tabbar.name, Tabbar);
Vue.component(Button.name, Button);
Vue.component(Field.name, Field);
Vue.component(Cell.name, Cell);
Vue.component(Navbar.name, Navbar);
Vue.component(TabItem.name, TabItem);
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);

Vue.config.productionTip = false;
Vue.use(MintUI)
Vue.use(VueInputMask)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
