<template>
  <div class="home">
<!--    <mt-popup-->
<!--      v-model="isPopup"-->
<!--      popup-transition="popup-fade"-->
<!--      position="top"-->
<!--      modal="true"-->
<!--      class="mint-popup-2"-->
<!--    >-->
<!--      <div-->
<!--        style="-->
<!--          display: flex;-->
<!--          justify-content: center;-->
<!--          align-items: center;-->
<!--          height: 100px;-->
<!--        "-->
<!--      >-->
<!--        <i-->
<!--            class="mintui "-->
<!--            :class="{'mintui-field-error': !resultState, 'mintui-field-success': resultState}"-->
<!--            style="font-size: 30px;margin-right: 5px"-->
<!--            :style="{'color': (resultState) ? '#4caf50' : '#f44336'}"-->
<!--        ></i> {{resultMsg}}-->
<!--      </div>-->

<!--    </mt-popup>-->


    <div class="header">
      <h1>
        <span class="myacuvue">MyACUVUE© </span>
        <span class="wildberries" v-if="curLense.seller=='ooo'">+ НЕОВИЖН</span>
        <span class="wildberries" v-if="curLense.seller=='ip'">+ EYE GALLERY</span>
        <span class="wildberries" v-if="curLense.seller=='vipl'">+ ВИПЛИНЗА</span>
      </h1>
    </div>

    <img v-if="!resultState" src="../assets/main-img.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==100" src="../assets/main-img.png" alt="" style="width: 100%;margin-top: 37px">
    <div v-if="resultState && (resultCode==0 || resultCode==51)" style="position: relative;display: flex;justify-content: center;">
      <img src="../assets/success_girl.jpg" alt="" style="width: 100%;margin-top: 52px;">
      <div style="position: absolute;top:60%;left:10%;font-size: 48px;color:#fff;font-weight: bold">+ 120</div>
    </div>

    <img v-if="resultState && resultCode<0" src="../assets/neok.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==1" src="../assets/neok.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==2" src="../assets/neok.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==3" src="../assets/neok.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==4" src="../assets/neok.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==6" src="../assets/neok.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==7" src="../assets/neok.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==8" src="../assets/neok.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==9" src="../assets/warning.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==10" src="../assets/warning.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==11" src="../assets/warning.png" alt="" style="width: 100%;margin-top: 37px">

    <img v-if="resultState && resultCode==5" src="../assets/warning.png" alt="" style="width: 100%;margin-top: 37px">
    <img v-if="resultState && resultCode==101" src="../assets/ok.png" alt="" style="width: 100%;margin-top: 37px">
<!--    <img v-if="resultState && resultCode==51" src="../assets/ok.png" alt="" style="width: 100%;margin-top: 37px">-->

<!--      <i-->
<!--          class="mintui "-->
<!--          :class="{'mintui-field-error': !resultState, 'mintui-field-success': resultState}"-->
<!--          style="font-size: 30px;margin-right: 5px"-->
<!--          :style="{'color': (resultState) ? '#4caf50' : '#f44336'}"-->
<!--      ></i> -->

    <template v-if="resultState">
      <div v-if="resultCode<0" class="error-block">
        <p>Информация по данному коду не найдена. </p>
        <p>Вы можете связаться с поддержкой через Telegram: <a href="https://t.me/myacuvue">@myacuvue</a></p>
      </div>
      <div v-if="resultCode==0 || resultCode==51" class="success-block success-page">
        <div class="point-circle">Всего у Вас<span>{{ userAcuvuePoint }}</span>баллов</div>

        <div class="review-block">
          <p>Мы будем благодарны вам за отзыв с фотографией.</p>
          <a :href="curLense.linkForReview" v-if="curLense.market=='ozon'"><mt-button type="primary">Перейти на Wildberries</mt-button></a>
<!--          <a :href="#" v-else><mt-button type="primary">Перейти на Ozon</mt-button></a>-->
          <p style="font-weight:300;color: #757575;line-height: 19px">Пожалуйста, не упоминайте в тексте про начисление баллов MyACUVUE®</p>
        </div>

        <voucher1000-page :cur-lense="curLense" :phone="phone" :cur-key="curKey" />



      </div>
      <div v-if="resultCode==1" class="error-block">
        <p>Информация по данному коду не найдена.</p>
        <p> Мы уже получили уведомление об этой ошибке и работаем над её устранением.</p>
        <p>Вы можете связаться с поддержкой через Telegram: <a href="https://t.me/myacuvue">@myacuvue</a></p>
<!--        По данному товару баллы уже были начислены *дата* (если не сложно будет ее вытаскивать) Вы можете связаться с поддержкой через Telegram-->
      </div>
      <div v-if="resultCode==2" class="error-block">
        <p>Данный товар не участвует в программе MyACUVUE®.</p>
        <p>Чтобы начислить баллы MyACUVUE, пожалуйста, пришлите фотографию упаковки линз в чат поддержки в Telegram: <a href="https://t.me/MyAcuvue">@MyAcuvue</a></p>
        <p><b>MyACUVUE</b> - официальная программа ООО «Джонсон & Джонсон» для носителей контактных линз ACUVUE.</p>
      </div>
      <div v-if="resultCode==3" class="error-block">
        <p>При начислении баллов возникла ошибка, мы уже знаем о ней. </p>
        <p>Информация зафиксирована и передана на проверку. </p>
        <p>В ближайшие дни баллы будут начислены, вы получите уведомление об этом. </p>
        <p>Вы можете связаться с поддержкой через Telegram: <a href="https://t.me/myacuvue">@myacuvue</a></p>
      </div>
      <div v-if="resultCode==4" class="error-block">
        <p>К сожалению, ваша учетная запись в программе MyACUVUE® заблокирована и сейчас невозможно начислить баллы.</p>
        <p>По <a href="https://www.acuvue.ru/pravila-programmy-myacuvue">(правилам программы, пункт 6.5)</a>, в случае приобретения товара, характеристики которого отличаются от товара, который был подобран, после 4-ой некорректной покупки учетная запись может быть заблокирована.</p>
        <p>Так же учетная запись может быть заблокирована из-за многократного ввода неправильного пароля или других проблем с приложением.</p>
      <p>Для разблокировки учетной записи обратитесь на горячую линию программы MyACUVUE® по телефону: <a href="tel:88006007997">8 800 600 79 97</a></p>
      <p>После разблокировки учетной записи MyACUVUE® вы сможете повторить начисление баллов за данную покупку по qr-коду с наклейки.</p>
        <p>Если учетная запись MyACUVUE® заблокирована навсегда, то вы можете завести новую на другой номер телефона.</p>
        <br>
        <p>Если вы считаете, что произошла ошибка, пожалуйста, свяжитесь с нами через раздел контакты.</p>
      </div><!-- + -->
      <div v-if="resultCode==5" class="error-block">
        <p>К сожалению, купленный вами товар <b>{{curLense.name}}</b>, отличается о подобранного в программе MyACUVUE®.</p>
        <p>По (правилам программы, пункт 3.1), баллы начисляются на Бонусный счёт Участника при совершении покупки товаров, которые отражены в личном кабинете участника.</p>
        <div>
          В вашем кабинете отображаются следующие товары из истории подборов:
          <mt-cell
              is-link
              v-for="(fit, indx) of fittings.filter(e=>e.art_wb)"
              :key="indx"
              :title='`${fit.brand} ${fit.params} (${fit.dateFormat})`'
              :value="`артикул ${fit.art_wb}`"
              :to="`https://www.wildberries.ru/catalog/${fit.art_wb}/detail.aspx?targetUrl=MI`"
              style="margin: 5px 0;border:1px solid #e7e7e7"
              target="_blank"
          ></mt-cell>
          <mt-cell
              is-link
              v-for="(fit, indx) of fittings.filter(e=>!e.art_wb)"
              :key="indx"
              :title='`${fit.brand} ${fit.params} (${fit.dateFormat})`'
              style="margin: 5px 0;border:1px solid #e7e7e7"
              target="_blank"
          ></mt-cell>
        </div>

        <p>При покупке товаров, соответствующих вашим подбору, баллы начислятся автоматически без ошибки.</p>
        <p>Для начисления баллов за текущую покупку <b>{{curLense.name}}</b> вам необходимо обратиться в оптику-участницу программы MyACUVUE® и пройти подбор линз <b>{{curLense.model}}</b>.</p>
        <p>Найдите ближайшую оптику, участвующую в программе на карте: <a href="https://www.myacuvue.acuvue.ru/get-contact-lenses" target="_blank">https://www.myacuvue.acuvue.ru/get-contact-lenses</a></p>
        <br>
        <p>Если вы считаете, что произошла ошибка, пожалуйста, свяжитесь с нами через раздел контакты.</p>
      </div><!-- + -->
      <div v-if="resultCode==10" class="error-block">
        <p>К сожалению, купленный вами товар <b>{{curLense.name}}</b>, отличается о подобранного в программе MyACUVUE®.</p>
        <div>
          В вашем кабинете отображаются следующие товары из истории подборов:
          <mt-cell
              is-link
              v-for="(fit, indx) of fittings.filter(e=>e.art_wb)"
              :key="indx"
              :title='`${fit.brand} ${fit.params} (${fit.dateFormat})`'
              :value="`артикул ${fit.art_wb}`"
              :to="`https://www.wildberries.ru/catalog/${fit.art_wb}/detail.aspx?targetUrl=MI`"
              style="margin: 5px 0;border:1px solid #e7e7e7"
              target="_blank"
          ></mt-cell>
          <mt-cell
              is-link
              v-for="(fit, indx) of fittings.filter(e=>!e.art_wb)"
              :key="indx"
              :title='`${fit.brand} ${fit.params} (${fit.dateFormat})`'
              style="margin: 5px 0;border:1px solid #e7e7e7"
              target="_blank"
          ></mt-cell>
        </div>
        <p>При покупке товаров, соответствующих вашим подбору, баллы начислятся автоматически без ошибки.</p>
        <p>В соответствии <a href="https://www.acuvue.ru/pravila-programmy-myacuvue" target="_blank">(с пунктом 3.1 правил программы  MyACUVUE®)</a>, баллы начисляются на Бонусный счёт Участника при совершении покупки Товаров, которые отражены в Личном кабинете Участника.</p>
        <p>По <a href="https://www.acuvue.ru/pravila-programmy-myacuvue" target="_blank">(правилам программы, пункт 6.5)</a>, в случае приобретения товара, характеристики которого отличаются от товара, который был подобран, после 4-ой некорректной покупки учетная запись может быть заблокирована.</p>
        <p>Мы рекомендуем вам пройти новый подбор в оптике, участвующей в программе MyACUVUE® <a href="https://www.myacuvue.acuvue.ru/get-contact-lenses" target="_blank">https://www.myacuvue.acuvue.ru/get-contact-lenses</a>.</p>
        <p> Нажмите кнопку "Продолжить", если вы хотите начислить баллы в любом случае.</p>
        <mt-button type="primary" @click="sendDataWithError()">Продолжить</mt-button>

        <br>
        <p>Если вы считаете, что произошла ошибка, пожалуйста, свяжитесь с нами через раздел контакты.</p>
      </div>
      <div v-if="resultCode==11" class="error-block">
        <p style="text-align: center">По этому qr-коду баллы уже были начислены</p>
      </div>
      <div v-if="resultCode==6" class="error-block">
        <p>В настоящее время нет связи с сервером MyACUVUE®.</p>
        <p>Попробуйте ещё раз через несколько часов.</p>
        <p>Вы можете связаться с поддержкой через Telegram: <a href="https://t.me/myacuvue">@myacuvue</a></p>
      </div>
      <div v-if="resultCode==7" class="error-block">
        <p>При начислении баллов возникла ошибка, мы уже знаем о ней.</p>
        <p>Информация зафиксирована и передана на проверку.</p>
        <p>В ближайшие дни баллы будут начислены, вы получите уведомление об этом.</p>
        <p>Вы можете связаться с поддержкой через Telegram: <a href="https://t.me/myacuvue">@myacuvue</a></p>
      </div>
      <div v-if="resultCode==8" class="error-block">
        <p>На этот номер телефона не зарегистрирован пользователь MyACUVUE®.</p>
        <p>Баллы начисляются только для участников программы.</p>
        <p>Вы можете найти ближайшую оптику и пройти бесплатную регистрацию в программе: <a href="https://www.myacuvue.acuvue.ru/get-contact-lenses" target="_blank">https://www.myacuvue.acuvue.ru/get-contact-lenses</a></p>
      </div>
      <div v-if="resultCode==9" class="error-block">
        <p>У Вас нет подходящих подборов</p>
        <p>Вы можете найти ближайшую оптику и пройти бесплатную регистрацию в программе: <a href="https://www.myacuvue.acuvue.ru/get-contact-lenses" target="_blank">https://www.myacuvue.acuvue.ru/get-contact-lenses</a></p>
      </div>

      <div v-if="resultCode==100" class="success-block">
        <p>Вы можете потратить баллы MyACUVUE® на скидку при покупке линз Acuvue в размере <b>300</b> или <b>500</b> рублей в любой оптике-участнике программы MyAcuvue в России или заказать линзы с доставкой в любой город на сайте
          <br><a href="https://viplinza.ru" style="font-size: 22px">viplinza.ru</a></p>
        <p>Оптики обозначены логотипом программы MyACUVUE®, список магазинов доступен в приложении MyACUVUE®. </p>
        <p>Скидка применяется оптикой при продаже или онлайн при оформлении заказа.</p>

        <div style="background-color: #fff;width: 100%;margin: 12px 0;border-radius: 18px;box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);padding: 0px 12px 30px 12px;">

          <p style="font-size:16px;color:#000;font-weight: bold;text-align: left;padding-left: 12px">Варианты скидок</p>

          <div style="display: flex;flex-direction: row;justify-content: space-evenly;">
            <div class="success-panel" style="border:1px solid #D0D0D0">
              <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_128_220)">
                  <rect x="4.5" y="3" width="48" height="48" rx="24" fill="#05A3E1"/>
                  <path d="M22.332 35.4L32.436 18.6H34.668L24.564 35.4H22.332ZM19.476 19.752C20.196 18.856 21.196 18.408 22.476 18.408C23.756 18.408 24.756 18.856 25.476 19.752C26.196 20.632 26.556 21.784 26.556 23.208C26.556 24.632 26.196 25.792 25.476 26.688C24.756 27.568 23.756 28.008 22.476 28.008C21.196 28.008 20.196 27.568 19.476 26.688C18.756 25.792 18.396 24.632 18.396 23.208C18.396 21.784 18.756 20.632 19.476 19.752ZM24.636 23.208C24.636 21.064 23.916 19.992 22.476 19.992C21.036 19.992 20.316 21.064 20.316 23.208C20.316 25.352 21.036 26.424 22.476 26.424C23.916 26.424 24.636 25.352 24.636 23.208ZM31.524 27.336C32.244 26.44 33.244 25.992 34.524 25.992C35.804 25.992 36.804 26.44 37.524 27.336C38.244 28.216 38.604 29.368 38.604 30.792C38.604 32.216 38.244 33.376 37.524 34.272C36.804 35.152 35.804 35.592 34.524 35.592C33.244 35.592 32.244 35.152 31.524 34.272C30.804 33.376 30.444 32.216 30.444 30.792C30.444 29.368 30.804 28.216 31.524 27.336ZM36.684 30.792C36.684 28.648 35.964 27.576 34.524 27.576C33.084 27.576 32.364 28.648 32.364 30.792C32.364 32.936 33.084 34.008 34.524 34.008C35.964 34.008 36.684 32.936 36.684 30.792Z" fill="white"/>
                </g>
                <defs>
                  <filter id="filter0_d_128_220" x="0.5" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_128_220"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_128_220" result="shape"/>
                  </filter>
                </defs>
              </svg>

              <div style="font-size:12px;font-weight: normal;">Скидка 300 ₽ на одну покупку</div>
<!--              <a href="/howspendpoints"><mt-button type="primary">Как получить</mt-button></a>-->
<!--              <a ><mt-button type="default" disabled>Нужно 300 баллов</mt-button></a>-->
            </div>

            <div class="success-panel" style="border:1px solid #D0D0D0">
              <svg  width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_128_220)">
                  <rect x="4.5" y="3" width="48" height="48" rx="24" fill="#05A3E1"/>
                  <path d="M22.332 35.4L32.436 18.6H34.668L24.564 35.4H22.332ZM19.476 19.752C20.196 18.856 21.196 18.408 22.476 18.408C23.756 18.408 24.756 18.856 25.476 19.752C26.196 20.632 26.556 21.784 26.556 23.208C26.556 24.632 26.196 25.792 25.476 26.688C24.756 27.568 23.756 28.008 22.476 28.008C21.196 28.008 20.196 27.568 19.476 26.688C18.756 25.792 18.396 24.632 18.396 23.208C18.396 21.784 18.756 20.632 19.476 19.752ZM24.636 23.208C24.636 21.064 23.916 19.992 22.476 19.992C21.036 19.992 20.316 21.064 20.316 23.208C20.316 25.352 21.036 26.424 22.476 26.424C23.916 26.424 24.636 25.352 24.636 23.208ZM31.524 27.336C32.244 26.44 33.244 25.992 34.524 25.992C35.804 25.992 36.804 26.44 37.524 27.336C38.244 28.216 38.604 29.368 38.604 30.792C38.604 32.216 38.244 33.376 37.524 34.272C36.804 35.152 35.804 35.592 34.524 35.592C33.244 35.592 32.244 35.152 31.524 34.272C30.804 33.376 30.444 32.216 30.444 30.792C30.444 29.368 30.804 28.216 31.524 27.336ZM36.684 30.792C36.684 28.648 35.964 27.576 34.524 27.576C33.084 27.576 32.364 28.648 32.364 30.792C32.364 32.936 33.084 34.008 34.524 34.008C35.964 34.008 36.684 32.936 36.684 30.792Z" fill="white"/>
                </g>
                <defs>
                  <filter id="filter0_d_128_220" x="0.5" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_128_220"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_128_220" result="shape"/>
                  </filter>
                </defs>
              </svg>

              <div style="font-size:12px;font-weight: normal;">Скидка 500 ₽ <br>на одну покупку</div>
<!--              <a   href="/howspendpoints"><mt-button type="primary">Как получить</mt-button></a>-->
<!--              <a ><mt-button type="default" disabled>Нужно 500 баллов</mt-button></a>-->
            </div>
          </div>

        </div>

      </div>

      <div v-if="resultCode==101" class="success-block">
<!--        <template v-if="curLense.seller=='ooo'">-->
<!--          НЕОВИЖН-->
<!--        </template>-->
<!--        <template v-if="curLense.seller=='ip'">-->
<!--          EYE GALLERY-->
<!--        </template>-->
<!--        <template v-if="curLense.seller=='vipl'">-->
<!--          ВИПЛИНЗА-->
<!--        </template>-->

<!--        <p>Контакты:</p>-->
<!--        <p>Адрес</p>-->

       <h2>Мы будем рады вашей обратной связи!</h2>
        <a style="width: 100%;" href="https://t.me/myacuvue" target="_blank"><mt-button type="primary">Пишите нам в телеграм @myacuvue </mt-button></a>
        <a style="width: 100%;" href="tel:88007074751" target="_blank"><mt-button type="primary">Звоните 88007074751</mt-button></a>
        <template v-if="!sendedText">
          <mt-field :style="{'border': (isValidText) ? `1px solid #4caf50` : `1px solid #f44336`}" style="width: 80%;margin-top: 42px;" placeholder="Введите Ваш вопрос" type="textarea" rows="2" v-model="feedBackText"></mt-field>
          <a class="mint-cell mint-field is-nolabel contact-form" style="width: 80%;margin-top: 10px;">
            <div class="mint-cell-left"></div>
            <div class="mint-cell-wrapper" :style="{'border':`1px solid ${(!isValidPhone ? '#f00' : '#4caf50')}`}">
              <div class="mint-cell-value">
                <!--            <input-->
                <!--                v-model="phone"-->
                <!--                placeholder="+7 (___) ___-__-__"-->
                <!--                type="tel"-->
                <!--                v-mask="'+7 (999) 999-99-99'"-->
                <!--                class="mint-field-core"-->
                <!--                @paste.prevent="pnum()"-->
                <!--            />-->
                <input
                    v-model="phone"
                    placeholder="+7 (___) ___-__-__"
                    type="tel"
                    v-mask="'+7 (999) 999-99-99'"
                    class="mint-field-core"
                    @paste="pnum($event)"
                />

                <div v-if="isValidPhone">
                  <span class="mint-field-state is-success"><i class="mintui mintui-field-success"></i></span>
                </div>

                <div  v-if="!isValidPhone" @click="phone=''">
                  <span class="mint-field-state is-error"><i class="mintui mintui-field-error"></i></span>
                </div>

                <span class="mint-field-state is-default">
            <i class="mintui mintui-field-default"></i>
          </span>
                <div class="mint-field-other"></div>
              </div>
            </div>
          </a>
          <mt-button type="primary" @click.prevent="sendFeedback()" :disabled="!isValidText">Отправить</mt-button>
        </template>
        <template v-else>
          <h3>Вопрос отправили</h3>
        </template>

      </div>

    </template>

    <div v-if="curLense.art_wb && !resultState" class="lenses">
      <div class="wbimg" :class="{card: curLense.wb_img==''}" style="position:relative;min-width: 150px">
        <img v-if="curLense.wb_img!=''" :src="curLense.wb_img" alt="">
        <div v-else class="mint-spinner-snake" style="position:absolute;top:35%;left:30%;border-top-color: rgb(204, 204, 204); border-left-color: rgb(204, 204, 204); border-bottom-color: rgb(204, 204, 204); height: 32px; width: 32px;"></div>
      </div>
      <div class="wbinfo">
        <a style="text-decoration: none" :href="`https://www.wildberries.ru/catalog/${curLense.art_wb}/detail.aspx?targetUrl=XS`" target="_blank">
        <div class="card">
          <div>Ваши линзы:</div>
<!--          <div>Acuvue Oasys with Hydraclear [8.5;-2.5]</div>-->
          <div>{{ curLense.name }}</div>
          <div>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="25" height="25" rx="12.5" fill="#A72889"/>
              <path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
            </svg>
          </div>
        </div>
        </a>
      </div>
    </div>
    <div v-if="curLense.market=='ozon' && !resultState" class="lenses">
<!--      <div class="wbimg" :class="{card: curLense.wb_img==''}" style="position:relative;min-width: 150px">-->
<!--        <img v-if="curLense.wb_img!=''" :src="curLense.wb_img" alt="">-->
<!--        <div v-else class="mint-spinner-snake" style="position:absolute;top:35%;left:30%;border-top-color: rgb(204, 204, 204); border-left-color: rgb(204, 204, 204); border-bottom-color: rgb(204, 204, 204); height: 32px; width: 32px;"></div>-->
<!--      </div>-->
      <div class="wbinfo">
        <a >
        <div class="card">
          <div>Ваши линзы:</div>
<!--          <div>Acuvue Oasys with Hydraclear [8.5;-2.5]</div>-->
          <div>{{ curLense.name }}</div>
          <div>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="25" height="25" rx="12.5" fill="#A72889"/>
              <path d="M10 17.5L15 12.5L10 7.5" stroke="white"/>
            </svg>
          </div>
        </div>
        </a>
      </div>
    </div>
    <div v-if="curLense.art_wb && !resultState" class="acuvue-form">
<!--      <div class="text">Получайте баллы MyACUVUE© за покупки на Wildberries!</div>-->
      <div class="text">Если у Вас есть подбор контактных линз в программе MyACUVUE®, за эту покупку вы получите </div>
      <div class="text" style="align-self: center;"><b>{{ curLense.acuvuePoint }} баллов</b></div>
      <div class="text">Для начисления баллов введите ваш номер телефона участника программы MyACUVUE®:</div>
      <a class="mint-cell mint-field is-nolabel">
        <div class="mint-cell-left"></div>
        <div class="mint-cell-wrapper" :style="{'border':`1px solid ${(!isValidPhone ? '#f00' : '#4caf50')}`}">
          <div class="mint-cell-value">
<!--            <input-->
<!--                v-model="phone"-->
<!--                placeholder="+7 (___) ___-__-__"-->
<!--                type="tel"-->
<!--                v-mask="'+7 (999) 999-99-99'"-->
<!--                class="mint-field-core"-->
<!--                @paste.prevent="pnum()"-->
<!--            />-->
            <input
                v-model="phone"
                placeholder="+7 (___) ___-__-__"
                type="tel"
                v-mask="'+7 (999) 999-99-99'"
                class="mint-field-core"
                @paste="pnum($event)"
            />

            <div v-if="isValidPhone">
              <span class="mint-field-state is-success"><i class="mintui mintui-field-success"></i></span>
            </div>

          <div  v-if="!isValidPhone" @click="phone=''">
            <span class="mint-field-state is-error"><i class="mintui mintui-field-error"></i></span>
          </div>

          <span class="mint-field-state is-default">
            <i class="mintui mintui-field-default"></i>
          </span>
          <div class="mint-field-other"></div>
        </div>
        </div>
      </a>
      <div class="mint-checklist">
        <label class="mint-checklist-title"></label>
        <a class="mint-cell">
          <div class="mint-cell-left"></div>
          <div class="mint-cell-wrapper">
            <div class="mint-cell-title">
              <label class="mint-checklist-label">
                  <span class="mint-checkbox">
                    <input type="checkbox" class="mint-checkbox-input" value="valueF" checked>
                    <span class="mint-checkbox-core"></span></span>
                <span class="mint-checkbox-label">Я согласен (согласна) с  данными условиями</span>
              </label>
            </div>
            <div class="mint-cell-value"><span></span></div>
          </div> <div class="mint-cell-right"></div>
        </a>
      </div>
      <mt-button :disabled="!isValidPhone" type="primary" @click="sendData(phone)">
        Начислить баллы
      </mt-button>
<!--      <vue-recaptcha sitekey="Your key here"></vue-recaptcha>-->
      <div class="text" style="margin-top: 22px">Если вы еще не участник программы, то вы можете посетить ближайшую оптику для бесплатного подбора линз Acuvue и регистрации в программе.
        <a class="link" href="https://www.myacuvue.acuvue.ru/get-contact-lenses" target="_blank">Найти оптику</a> </div>

    </div>
    <div v-if="curLense.market=='ozon' && !resultState" class="acuvue-form">
      <div class="text">Если у Вас есть подбор контактных линз в программе MyACUVUE®, за эту покупку вы получите </div>
      <div class="text" style="align-self: center;"><b>{{ curLense.acuvuePoint }} баллов</b></div>
      <div class="text">Для начисления баллов введите ваш номер телефона участника программы MyACUVUE®:</div>
      <a class="mint-cell mint-field is-nolabel">
        <div class="mint-cell-left"></div>
        <div class="mint-cell-wrapper" :style="{'border':`1px solid ${(!isValidPhone ? '#f00' : '#4caf50')}`}">
          <div class="mint-cell-value">
            <input
                v-model="phone"
                placeholder="+7 (___) ___-__-__"
                type="tel"
                v-mask="'+7 (999) 999-99-99'"
                class="mint-field-core"
                @paste="pnum($event)"
            />

            <div v-if="isValidPhone">
              <span class="mint-field-state is-success"><i class="mintui mintui-field-success"></i></span>
            </div>

          <div  v-if="!isValidPhone" @click="phone=''">
            <span class="mint-field-state is-error"><i class="mintui mintui-field-error"></i></span>
          </div>

          <span class="mint-field-state is-default">
            <i class="mintui mintui-field-default"></i>
          </span>
          <div class="mint-field-other"></div>
        </div>
        </div>
      </a>
      <div class="mint-checklist">
        <label class="mint-checklist-title"></label>
        <a class="mint-cell">
          <div class="mint-cell-left"></div>
          <div class="mint-cell-wrapper">
            <div class="mint-cell-title">
              <label class="mint-checklist-label">
                  <span class="mint-checkbox">
                    <input type="checkbox" class="mint-checkbox-input" value="valueF" checked>
                    <span class="mint-checkbox-core"></span></span>
                <span class="mint-checkbox-label">Я согласен (согласна) с  данными условиями</span>
              </label>
            </div>
            <div class="mint-cell-value"><span></span></div>
          </div> <div class="mint-cell-right"></div>
        </a>
      </div>
      <mt-button :disabled="!isValidPhone" type="primary" @click="sendData(phone)">
        Начислить баллы
      </mt-button>
<!--      <vue-recaptcha sitekey="Your key here"></vue-recaptcha>-->
      <div class="text" style="margin-top: 22px">Если вы еще не участник программы, то вы можете посетить ближайшую оптику для бесплатного подбора линз Acuvue и регистрации в программе.
        <a class="link" href="https://www.myacuvue.acuvue.ru/get-contact-lenses" target="_blank">Найти оптику</a> </div>

    </div>
    <div v-if="!resultState" class="list">
      <mt-cell
          v-if="resultMsg == ''"
          title="Как потратить баллы и получить скидку?"
          to="/howspendpoints"
          is-link
          value="">
      </mt-cell>
<!--      <mt-cell-->
<!--          v-if="resultMsg != ''"-->
<!--          title="Если вам все понравилось, то вы можете помочь нам, поставив ❤ на странице этого бренда."-->
<!--          :to="curLense.linkForBrand"-->
<!--          is-link-->
<!--          value="">-->
<!--      </mt-cell>-->
      <mt-cell
          v-if="resultMsg == ''"
          title="Мы будем рады вашей обратной связи! Пишите нам в телеграм @myacuvue "
          :to="curLense.linkForTelegram"
          is-link
          value="">
      </mt-cell>
    </div>


<!--    <hr>-->
<!--    isError {{resultState}} <input type="checkbox" v-model="resultState"><br>-->
<!--    resultCode {{resultCode}} <input type="text" v-model="resultCode"> <br>-->
<!--&lt;!&ndash;    resultMsg {{resultMsg}} <br>&ndash;&gt;-->
<!--    <hr>-->
  </div>
</template>
<style>
.mint-button-text {
  font-family: 'Pragmatica'!important;
  font-weight: 400!important;
  font-size: 19px!important;
}
/*successpage*/
.success-page {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.success-page .point-circle {
  margin-top:12px;
  border-radius: 100%;
  border:1px solid #05A3E1;
  width: 145px;
  height: 145px;
  background-color: #05A3E1;
  color:#fff;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.point-circle span{
  font-size: 38px;
  font-weight: bold;
  padding: 10px 0 5px 0;
}
.success-page .review-block {
  background-color: #fff;
  width: 100%;
  margin-top: 12px;
  border-radius: 18px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  padding: 0px 12px 12px 12px;
}
.review-block p {
  text-align: left;
}
.success-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 12px;
  text-align: center;
  background-color: #F1FAFF;
}
.success-block .mint-button {
  background: #A72889;
  border-radius: 42px!important;
  width: 100%;
  margin: 12px 0;
}
.success-page .calc-line {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 5px 0;
}
/**/
p {
  color: #1B1B1B;
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
.mint-checkbox-core::after {
  border: 2px solid transparent;
  border-left: 0;
  border-top: 0;
  content: " ";
  top: 1px!important;
  left: 6px;
  position: absolute;
  width: 7px!important;
  height: 10px!important;
  transform: rotate(45deg) scale(0);
  transition: transform .2s;
}
.error-block {
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  flex-direction: column;
  padding: 0px 12px;
  /*height: 100px;*/
}
.error-block p {
  margin: 10px 0;
}
.error-block .mint-cell-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 42px;
}
.error-block .mint-cell {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 42px;
}
.error-block .mint-button {
  background: #059FE2;
  border-radius: 42px!important;
  width: 100%;
  margin: 10px 0 0 0;
}

.success-panel {
  margin-top:12px;
  border-radius: 18px;
  border:1px solid #D0D0D0;
  width: 145px;
  height: 185px;
  color:#000;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.success-panel .mint-button {
  background: #05A3E1;
  border-radius: 42px!important;
  width: 100%;
  margin: 12px 0;
  height: 25px;
  font-size: 12px
}
.success-panel.deactive .mint-button {
  background: #fff;
  border-radius: 42px!important;
  width: 100%;
  margin: 12px 0;
  height: 25px;
  font-size: 12px
}


.mint-popup-2{
  width: 100%;
}

.myacuvue {
  color: #1B1B1B;
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  background: #1B1B1B;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /*background-clip: text;*/
  text-fill-color: transparent;

}
.wildberries {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  background: linear-gradient(45.28deg, #A92284 24.69%, #53237D 75.86%), #000000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /*background-clip: text;*/
  text-fill-color: transparent;
}
.header {
  display: flex;
  justify-content: left;
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 0px 12px 5px 12px;
  z-index: 100
}

.lenses {
  display: flex;
  justify-content: space-evenly;
  padding: 0 12px;
  /*gap: 10px*/
}
.lenses img {
  max-width: 150px;
}
.lenses .wbinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lenses .card {
  /*border: 1px solid #000;*/
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 10px;
  margin-left: 12px;
}
.lenses .card div {
  text-align: center;
  margin: 0 12px 12px 12px;
  font-family: "Pragmatica";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #a72889;
}
.lenses .card div:nth-child(1) {
  color: #000000;
}

.lenses .icon {
  width: 25px;
  height: 25px;

  background: #A72889;
  border-radius: 38px;
}
.acuvue-form {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin: 20px 0;
}
.acuvue-form .text {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 19px;
  margin-bottom: 20px;
  color: #000000;
}
.acuvue-form button {
  margin-top: 20px;
}

.acuvue-form .mint-cell-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 42px;
}
.acuvue-form .mint-cell {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 42px;
}
.acuvue-form .mint-button {
  background: #059FE2;
  border-radius: 42px!important;
}
.contact-form .mint-cell-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 42px;
}
.contact-form .mint-cell {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 42px;
}
.contact-form .mint-button {
  background: #059FE2;
  border-radius: 42px!important;
}

.list .mint-cell {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  margin: 8px 12px;
  min-height: 56px;
}

.mint-cell-wrapper {
  background-image: none!important;
}
.mint-cell-allow-right::after {
  border: solid 1px #069ED7!important;
  border-bottom-width: 0!important;
  border-left-width: 0!important;
  content: " ";
  top: 50%;
  right: 20px;
  position: absolute;
  width: 5px;
  height: 5px;
  transform: translateY(-50%) rotate(45deg);
}
.mint-msgbox-message {
  line-height: 30px!important;
}
.link {
  text-decoration: none;
  color: #26a2ff
}
</style>
<script>
// import getInfo from "@/api/lenses";
import { Indicator } from 'mint-ui';
import Voucher1000Page from "@/views/Voucher/Voucher1000";
// import { MessageBox } from 'mint-ui';
// import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: "main-page",
  props:['curKey'],
  data: ()=>{
    return {
      feedBackText: '',
      sendedText: false,
      checkboxValue: "true",
      isPopup: false,
      phone: '',
      curLense: {
        wb_img: '',
        name:'',
        model: '',
        acuvuePoint: 0,
        art_wb: null
      },
      fittings: [
        {
          "date": "2019-12-06",
          "dateFormat": "06.12.2019",
          "brand": "Acuvue Oasys 1-Day",
          "params": "8.5;-1",
          "art_wb": "12577136"
        },
        {
          "date": "2020-03-08",
          "dateFormat": "08.03.2020",
          "brand": "Acuvue Oasys 1-Day",
          "params": "8.5;-1.25",
          "art_wb": "12577136"
        },
        {
          "date": "2021-05-22",
          "dateFormat": "22.05.2021",
          "brand": "Acuvue Oasys 6",
          "params": "8.4;-1.25",
          "art_wb": "12577136"
        },
        {
          "date": "2021-06-29",
          "dateFormat": "29.06.2021",
          "brand": "Acuvue Oasys with Transitions",
          "params": "8.4;-1.25",
          "art_wb": "12577136"
        }
      ],
      resultMsg: '',
      resultState: false,
      resultCode: 0,
      userAcuvuePoint: 0
    }
  },
  async mounted() {
    const curKey = this.curKey
    if (this.isUUID(this.curKey)) await this.getData(curKey)

    // проверяем оставлял ли ранее этот юзер свой номер телефона
    const fromLSphone = localStorage.getItem('curphone')
    if (fromLSphone) this.phone = fromLSphone

    //костыль для страниц с контактами и как тратить баллы
    if (this.curKey=='howspendpoints') {
      this.resultState = true
      this.resultCode = 100
    } else if (this.curKey=='contacts') {
      this.resultState = true
      this.resultCode = 101
      const fromLS = localStorage.getItem('curlense')
      if (fromLS) this.curLense = JSON.parse(localStorage.getItem('curlense'))
    }
  //костыль чтоб скролить вверх страницу
    window.scrollTo({top:0,behavior:"smooth"})
  },
  methods: {
    pnum(evt) {
      const pasteData = evt.clipboardData.getData('text').match(/\d+/g)?.join("")
      if (pasteData.length>=11) {
        this.phone = pasteData.substring(pasteData.length-10)
      }
      // this.phone = evt.clipboardData.getData('text').substring(1)
      return true;
    },
    isUUID (uuid) {
      let s = "" + uuid;
      s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
      if (s === null) {
        return false;
      }
      return true;
    },
    async getData(curKey) {
      Indicator.open({text: 'Пожалуйста подождите, мы загружаем информацию.', spinnerType: 'snake'})

      const curPhone = localStorage.getItem('curphone') ?? 'no'
      const phone = (curPhone=='no') ? curPhone : curPhone.replace(/[^0-9]/g,"").slice(1)

     return fetch(`${process.env.VUE_APP_API_SERVER}/lenses-info/${curKey}/${phone}`, {
        method: 'GET',
        headers: {
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          'Content-Type':'application/json',
          "credentials": "include",
          "mode": 'no-cors',
          'Access-Control-Allow-Origin': '*',
        }
      })
          .then(resp=>resp.json())
          .then(resp=>{
            if (resp.code) {
              // есть код ошибки, показываем в зависимости от code, показываем нужную ошибку
              this.resultState = true
              this.resultCode = resp.code
              Indicator.close()
            } else {
              // code==0, ошибки нет, показываем главную страницу с формой для ввода номера телефона
              this.curLense = resp.extra
              localStorage.setItem('curlense', JSON.stringify(this.curLense))
              localStorage.setItem('lastKey', this.curKey)
              Indicator.close()
            }
            // console.log('this.curLense')
            // console.log(this.curLense)
          })
         .catch(err=>{
           console.log('err', err)
           this.resultState = true
           this.resultCode = 6
           // this.resultMsg = 'Что-то пошло не так =( Отсканируйте qr-код ещё раз'
           // this.resultState = false
           // this.isPopup = true
           Indicator.close()
         })

    },
    async sendData() {
      Indicator.open({text: 'Пожалуйста подождите, мы проверяем информацию. Не закрывайте этой окно, проверка может занять несколько минут', spinnerType: 'snake'})
      // console.log({
      //   art_wb: this.curLense.art_wb,
      //   phone: this.phone.replace(/[^0-9]/g,"").slice(1),
      //   key: this.curKey,
      //   wb_order_id: this.curLense.wb_order_id
      // })
        await fetch(`${process.env.VUE_APP_API_SERVER}/acuvue-point`, {
          method: 'POST',
          headers: {
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br',
            'Content-Type':'application/json',
            "credentials": "include",
            "mode": 'no-cors',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            art_wb: this.curLense.art_wb,
            phone: this.phone.replace(/[^0-9]/g,"").slice(1),
            key: this.curKey,
            wb_order_id: this.curLense.wb_order_id,
            cur_point: this.curLense.acuvuePoint
          })
        })
            .then(resp=>resp.json())
            .then(resp=>{
              localStorage.setItem('curphone', `+7${this.phone.replace(/[^0-9]/g,"").slice(1)}`)
              if (resp.code<0) throw 'acuvue error' // ошибка сервера
              if (resp.code==10 || resp.code==5) {
                //нет подбора или нужно подтверждение похожего подбора
                const allBrands = [
                  {'abbr': '1DATE', 'full': '1-Day Acuvue TruEye 30'},
                  {'abbr': 'AOH', 'full': 'Acuvue Oasys 6'},
                  {'abbr': '1DAM', 'full': '1-Day Acuvue Moist 30'},
                  {'abbr': 'AOHfA', 'full': 'Acuvue Oasys for astigmatism'},
                  {'abbr': '1DAMfA', 'full': '1-Day Acuvue Moist for Astigmatism'},
                  {'abbr': '1DAD', 'full': '1-Day Acuvue define'},
                  {'abbr': 'AOH1D', 'full': 'Acuvue Oasys 1-Day'},
                  {'abbr': '1DAMM', 'full': '1-Day Acuvue Moist Multifocal'},
                  {'abbr': 'AOH1DfA', 'full': 'Acuvue Oasys 1-Day for Astigmatism'},
                  {'abbr': 'ARL', 'full': 'Acuvue Revitalens 300 мл'},
                  {'abbr': 'AOwT', 'full': 'Acuvue Oasys with Transitions'},
                  {'abbr': 'DAMM', 'full': ''}
                ]
                // console.log(resp.extra.fittings)
                this.fittings = resp.extra.fittings
                    .map(e=>{
                  const curveL = e.fittedBrands[0].leftEye?.find(el=>el.property=="BASE_CURVE")?.value
                  const dioL = e.fittedBrands[0].leftEye?.find(el=>el.property=="POWER")?.value
                  const cylL = e.fittedBrands[0].leftEye?.find(el=>el.property=="CYLINDER")?.value
                  const axL = e.fittedBrands[0].leftEye?.find(el=>el.property=="AXIS")?.value
                  const curveR = e.fittedBrands[0].rightEye?.find(el=>el.property=="BASE_CURVE")?.value
                  const dioR = e.fittedBrands[0].rightEye?.find(el=>el.property=="POWER")?.value
                  const cylR = e.fittedBrands[0].leftEye?.find(el=>el.property=="CYLINDER")?.value
                  const axR = e.fittedBrands[0].leftEye?.find(el=>el.property=="AXIS")?.value
                      // console.log('left')
                      // console.log({curveL, dioL, cylL, axL})
                      let params = ''
                      if ((curveL && dioL && !cylL && !axL) || (curveR && dioR && !cylR && !axR)) params = ((curveL==curveR && dioL==dioR) || (!curveR || !dioR))
                          ? `${curveL};${dioL}`
                          : `Л ${curveL};${dioL} П ${curveR};${dioR}`

                      if ((curveL && dioL && cylL && axL) || (curveR && dioR && cylR && axR)) params = (curveL==curveR && dioL==dioR)
                          ? `${curveL};${dioL};${cylL};${axL}`
                          : `Л ${curveL};${dioL};${cylL};${axL} П ${curveR};${dioR};${cylR};${axR}`


                      const createdDat = e.createdDateTime.split("T")[0]

                  return {
                    date: createdDat,
                    dateFormat: createdDat.split('-').reverse().join("."),
                    brand: allBrands.find(elem=>elem.abbr==e.fittedBrands[0].brandId).full,
                    params,
                    approve_arts_wb: ['12577136', '66666']
                  }
                })
                    .reverse()
                // console.log(this.fittings)
              }
              if (!resp.code) {
                // this.resultMsg = 'Всё впорядке, баллы начислены'
                this.resultState = true
                this.resultCode = 0
                this.userAcuvuePoint = resp.extra.points
              } else {
                this.resultState = true
                this.resultCode = resp.code
              }

              // this.isPopup = true
              Indicator.close()
            })
            .catch((err)=>{
              console.log('err', err)
              this.resultCode = 6
              this.resultState = true
              // this.isPopup = true
              Indicator.close()
            })

    },
    async sendDataWithError() {
      Indicator.open({text: 'Пожалуйста подождите, мы проверяем информацию. Не закрывайте этой окно, проверка может занять несколько минут', spinnerType: 'snake'})

        await fetch(`${process.env.VUE_APP_API_SERVER}/acuvue-point-with-no-fitting`, {
          method: 'POST',
          headers: {
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br',
            'Content-Type':'application/json',
            "credentials": "include",
            "mode": 'no-cors',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            art_wb: this.curLense.art_wb,
            phone: this.phone.replace(/[^0-9]/g,"").slice(1),
            key: this.curKey,
            wb_order_id: this.curLense.wb_order_id,
            cur_point: this.curLense.acuvuePoint
          })
        })
            .then(resp=>resp.json())
            .then(resp=>{
              if (!resp.code) {
                // this.resultMsg = 'Всё впорядке, баллы начислены'
                this.resultState = true
                this.resultCode = 51
                this.userAcuvuePoint = resp.extra.points
              } else {
                this.resultState = true
                this.resultCode = resp.code
              }

              // this.isPopup = true
              Indicator.close()
            })
            .catch(err=>{
              console.log('err', err)
              this.resultState = true
              this.resultCode = 6
              this.isPopup = true
              Indicator.close()
            })

    },
    async sendFeedback() {
      Indicator.open({text: 'Пожалуйста подождите, отправляем сообщение.', spinnerType: 'snake'})

        await fetch(`${process.env.VUE_APP_API_SERVER}/send-feedback`, {
          method: 'POST',
          headers: {
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br',
            'Content-Type':'application/json',
            "credentials": "include",
            "mode": 'no-cors',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            key: localStorage.lastKey || 'without key',
            phone: this.phone.replace(/[^0-9]/g,"").slice(1),
            curlense: this.curLense,
            feedBackText: this.feedBackText
          })
        })
            .then(resp=>resp.json())
            .then(resp=>{
              if (!resp.code) {
                this.sendedText = true
                // this.resultMsg = 'Всё впорядке, баллы начислены'
                // this.resultState = true
                // this.resultCode = 51
                // this.userAcuvuePoint = resp.extra.points
              } else {
                // this.resultState = true
                // this.resultCode = resp.code
              }

              // this.isPopup = true
              Indicator.close()
            })
            .catch(err=>{
              console.log('err', err)
              // this.resultState = true
              // this.resultCode = -2
              // this.isPopup = true
              Indicator.close()
            })

    },
  },
  computed: {
    isValidPhone: function() {
      return this.phone.toString().match(/\d+/g)?.join("").length==11
    },
    isValidText: function() {
      return this.feedBackText.length>0
    }
  },
  components: {
    Voucher1000Page

  },
  watch: {
    'resultCode': function () {
      // console.log('new code')
      // console.log(this.resultCode)
      window.scrollTo({top:0,behavior:"smooth"})
    }
  }
};
</script>
