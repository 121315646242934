import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
        import("../views/HomePage")
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: () =>
        import("../views/CatalogPage")
  },
  {
    path: "/acuvue",
    name: "Acuvue",
    component: () =>
        import("../views/Acuvue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: "",
  routes,
});

export default router;
